import React, { useEffect, useState } from "react";
import PageMain from "../../components/PageMain";
import ServicesNavbar from "../../components/Services/ServicesNavbar";
import Services from "../../components/Home/Services";
import Industries from "../../components/Home/Industries";
import Form from "../../components/Home/Form";
import Footer from "../../components/Home/Footer";
import Network from "../../components/Network";
import Style from "./ournetwork.module.scss";
import GetAQuote from "../../components/GetAQuote";

const OurNetwork = () => {
  const renderSubOfficesList = () => {
    return (
      <ul className={`list-unstyled d-md-none ${Style.subOfficesList}`}>
        {headOffice.subOffices.map(({ text }, index) => (
          <li
            key={index}
            className={`px-4 py-3 ${Style.activeLocation} ${
              activeLocation === text ? Style.active : ""
            }`}
            onMouseEnter={() => setActiveLocation(text)}
          >
            <p
              className={`${Style["hov-network"]} text-center m-0`}
              style={{ whiteSpace: "nowrap" }}
            >
              {text}
            </p>
          </li>
        ))}
      </ul>
    );
  };

  let officeAddress = {
    saudi: {
      offices: {
        location: "Kingdom of Saudi Arabia",
        addressone:
          "Building No. 3475, Al Tail Street",
          addresstwo:
          "9080, Dhahrat Laban Dist, PC 12564",
          addressthree:
          "Riyadh, Kingdom of Saudi Arabia",
        manager: {
          contact: "Contact:",
          countryincharge:"Operations Manager - KSA",
          name: "Mr. Khalid Al Badi",
          email: "Khalid@alsioman.com",
          phone: "Tel No: +966 050 362 5981 ",
        },
      },
    },
    uae: {
      offices: {
        location: "United Arab Emirates",
        addressone:
          "ALSI Global LLC",
          // Alsi For Marine Services LLC
          addresstwo:
          "Dubai National Insurance Building",
          addressthree:
          "5th Floor, Office 502, Diera, Dubai, UAE.",
        manager: {
          contact: "Contact:",
          countryincharge:"Country Manager - UAE",
          name: "Dihan Dole",
          email: "info@alsioman.com",
          phone: "Tel No: +971 4 5850934, +971 52 3767001 ",
        },
      },
    },
    qatar: {
      offices: {
        location: "State of Qatar",
        addressone:
          "C12 AL Emadi Business Center 2nd Floor",
          addresstwo:
          "Office 35, P.O. Box: 30611",
          addressthree:
          "Doha, Qatar.",
        manager: {
          contact: "Contact:",
          countryincharge:"Branch Manager",
          name: "Adityan K J",
          email: "adityan@alsioman.com",
          phone: "Tel No: +974 4047 8563, +974 3362 5333",
        },
      },
    },
    oman: {
      offices: {
        location: "Sulatanate of Oman",
        addressone: "ALSI Global LLC",
         // Alsi For Marine Services LLC
        addresstwo:"P.O. Box 608. P.C: 322.",
        addressthree:"W. Sohar, Sultanate Of OMAN.",
        fax: "Fax No: +968 2675 5550",
        manager: {
          contact: "Contact:",
          countryincharge:"General Manager",
          name: "Mohammed Al Badi",
          phone: "Tel No: +968 2675 5598, +968 9131 1118",
          email: "info@alsioman.com",
        },
      },
    },
  };

  const [activeLocation, setActiveLocation] = useState("Sohar Freezone");
  const [isOpen, setIsOpen] = useState({
    oman: false,
    saudi: true,
    qatar: false,
    uae: false,
  });
  const [isHovered, setIsHovered] = useState({
    oman: true,
    saudi: false,
    qatar: false,
    uae: false,
  });
  const [isHoveredSubOffice, setIsHoveredSubOffice] = useState({});
  const [tooltip, setTooltip] = useState(true);
  const [headOffice, setHeadOffice] = useState({
    offices: {
      location: "Sulatanate of Oman",
      addressone: "Alsi Global LLC",
      addresstwo:"P.O. Box 608. P.C: 322.",
      addressthree:"W. Sohar, Sultanate Of OMAN",
      fax: "Fax No: +968 2675 5550",
      manager: {
        contact: "Contact:",
        countryincharge:"General Manager",
        name: "Mohammed Al Badi",
        email: "info@alsioman.com",
        phone: "Tel No: +968 2675 5598, +968 9662 9111",
      },
    },
    subOffices: [
      {
        text: "Sohar Freezone",
      },
      {
        text: "Falaj Al Qabil",
      },
      {
        text: "Wajajah Border",
      },
      {
        text: "Wadijizzi Border",
      },
      {
        text: "Al Madina Logistics Hub",
      },
      {
        text: "Muscat",
      },
      {
        text: "Salalah",
      },
      {
        text: "Duqm",
      },
    ],
  });

  let oman = [
    {
      text: "Sohar Freezone",
    },
    {
      text: "Falaj Al Qabil",
    },
    {
      text: "Hatta Border",
    },
    {
      text: "Khatmat Shikala Border",
    },
    {
      text: "Al Madina Logistics Hub",
    },
    {
      text: "Muscat",
    },
    {
      text: "Salalah",
    },
    {
      text: "Duqm",
    },
  ];
  let uae = [
    {
      text: "Deira, Dubai",
    },
    {
      text: "Hatta Border",
    },
  ];
  let qatar = [
    {
      text: "Doha",
    },
  ];
  let ksa = [
    {
      text: "Riyadh",
    },
    {
      text: "Rub Al Khali Border",
    },
  ];

  const getTooltipstate = () => {
    if (isOpen.oman || isOpen.saudi || isOpen.qatar || isOpen.uae) {
      return true;
    }
    return false;
  };

  const getSubOfficeAndOffice = () => {
    if (isHovered.oman) {
      return { subOffices: oman, office: { location: "Oman" } };
    }
    if (isHovered.qatar) {
      return { subOffices: qatar, office: { location: "Qatar" } };
    }
    if (isHovered.saudi) {
      return { subOffices: ksa, office: { location: "Saudi" } };
    }
    if (isHovered.uae) {
      return { subOffices: uae, office: { location: "UAE" } };
    }
    return [];
  };
  const handleCliCk = (event) => {
    let initialState = {
      oman: false,
      saudi: false,
      qatar: false,
      uae: false,
    };
    setIsOpen(true);
    setIsHovered({ ...initialState, [event.target.id]: true });
    console.log(officeAddress[event.target.id]);
    setHeadOffice({
      ...headOffice,
      offices: { ...officeAddress[event.target.id].offices },
    });
    if (event.target.id === "oman") {
      setActiveLocation("Sohar Freezone");
    } else if (event.target.id === "uae") {
      setActiveLocation("Business Bay, Dubai");
    } else if (event.target.id === "qatar") {
      setActiveLocation("Doha");
    } else if (event.target.id === "saudi") {
      setActiveLocation("Riyadh");
    }
  };
  const handleMouseEnter = (event) => {
    const { id } = event.target;
    let initialState = {
      oman: false,
      saudi: false,
      qatar: false,
      uae: false,
    };
    let selected = Object.keys(isHovered).filter(
      (key) => isHovered[key] && key
    );
    if (selected[0] === id) {
      setIsOpen({ ...initialState, [id]: false });
    } else {
      setIsOpen({ ...initialState, [id]: true });
    }

  };
  useEffect(() => {
    setHeadOffice({ ...headOffice, ...getSubOfficeAndOffice() });
  }, [isHovered]);

  const handleSubOfficeTooltip = (event) => {
    setTooltip(true);
  };
  const handleTooltipState = (event) => {
    let subofficeIds = headOffice.subOffices.map((office) => office.text);
  };

  const phoneNumbers = headOffice.offices.manager.phone
    .replace(/Tel No: /, '')
    .split(', ');
  const phoneNumbersString = phoneNumbers.join(', ');

  return (
    <div>
      <ServicesNavbar />
      <PageMain
        main={{
          style: {
            backgroundImage: "url(/images/Network/our-network-bg.webp)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          },
        }}
        title="Our Network"
        introduction={[
          {
            className: "fw-bold",
            text: "ALSI is a proud and active member of multiple freight networks consisting of a large consortium of independent logistics companies worldwide. In addition to our network of partners for general cargo, we have access to key expertise in project cargo, perishables, pharma, time-critical and dangerous goods movement.",
          },

          {
            text: (
              <>
                We are an active member of the following networks:
                <ul>
                  <li>
                    WCA – Over 9,411 registered independent logistics companies
                    in 194 countries
                  </li>
                  <li>
                    FIATA – Over 5,800 registered independent logistics
                    companies in 150 countries
                  </li>
                </ul>
                With greater combined cargo volumes that eclipse even the
                largest multinational logistics companies, our membership
                provides us with strong global reach and local knowledge.
              </>
            ),
          },
        ]}
      />
      <div className={Style["network"]}>
        <div className="container container-sm container-md container-lg container-xl container-xxl h-100">
          <div className="row h-100">
            <div
              className={`${Style["map"]} col-lg-6 h-100 d-flex justify-content-center align-items-center`}
            >
              <Network
                isOpen={isOpen}
                setIsOpen={getTooltipstate()}
                isHovered={isHovered}
                setIsHovered={setIsHovered}
                onClick={handleCliCk}
                onMouseEnter={handleMouseEnter}
                className=""
              />
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center align-items-xl-end flex-column h-100">
              <div className="w-75 text-white">
                <h1 className="">{headOffice.offices.location}</h1>
                <p className="fw-normal pt-1" style={{ lineHeight: "25px" }}>
                  {headOffice.offices.addressone}
                </p>
                <p className="fw-normal" style={{ lineHeight: "30px" }}>
                  {headOffice.offices.addresstwo}
                </p>
                <p className="fw-normal" style={{ lineHeight: "30px" }}>
                  {headOffice.offices.addressthree}
                </p>
                <p>{headOffice.offices.fax}</p>
              </div>
              <div className="w-75 text-white">
                <h3 className="fw-bold" style={{lineHeight:"60px"}}>
                  {headOffice.offices.manager.contact}
                </h3>
                <p className="fw-bold">{headOffice.offices.manager.name}</p>
                <p style={{fontWeight:"500"}}>{headOffice.offices.manager.countryincharge}</p>
                <p>
                  <a href={`tel:${phoneNumbers.join(',')}`} className="d-grid" style={{ lineHeight: "35px", color: 'inherit', textDecoration: 'none' }}>
                    {phoneNumbersString}
                  </a>
                </p>
                <p style={{ lineHeight: "28px" }}>
                <a href={`mailto:${headOffice.offices.manager.email}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                  {headOffice.offices.manager.email}
                </a>
              </p>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container container-sm container-md container-lg container-xl container-xxl mt-5">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center" style={{ paddingBottom: "15px" }}>
              Offices In {headOffice.offices.location}
            </h1>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center overflow-auto">
          {renderSubOfficesList()}
          <div className={`d-none d-md-flex ${Style.subOfficesContainer}`}>
            {headOffice.subOffices.map(({ text }, index) => (
              <div
                key={index}
                className={`px-4 py-3 d-flex justify-content-center align-items-center ${
                  index !== headOffice.subOffices.length - 1 &&
                  "border-end border-secondary-subtle"
                } ${Style.activeLocation} ${
                  activeLocation === text ? Style.active : ""
                }`}
                name={text}
                onMouseEnter={() => setActiveLocation(text)}
              >
                <p
                  className={`${Style["hov-network"]} text-center m-0`}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Services />
      <Industries />
      <Form />
      <Footer />
      <GetAQuote />
    </div>
  );
};

export default OurNetwork;
