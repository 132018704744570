import React, { useRef, useState } from "react";
import "./../../css/CareersFormCss/CareersForm.css";
import Captcha from "../Captcha/index";
import axios from "axios";

const CareersForm = () => {
  const form = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    message: "",
    email: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: name === "phone_number" ? value.replace(/\D/g, "") : value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let data = new FormData();
    for (let key in formData) {
      data.append(key, formData[key]);
    }

    axios
      .post(
        "https://script.google.com/macros/s/AKfycbwyqrF970lHB1CgFu-td6nlICmXVYJJSjgsyZxhzZH3VH5SKEYeYOmJyt7-PzaSwjsuMw/exec",
        data
      )
      .then(() => setIsSubmitted(true))
      .catch(() => console.log("Error occurred"));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({
      ...formData,
      file: file,
    });
  };

  const handleReset = () => {
    setIsSubmitted(false);
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      message: "",
    });
  };

  return (
    <>
      <div className="container-fluid split-form-container">
        <div className="container container-sm container-md container-lg container-xl container-xxl">
          <div className="row">
            <div className="col-lg-6 career-left d-flex justify-content-center align-items-center">
              <h1 className="career-left-text ">Be a part of ALSI</h1>
            </div>
            <div className="col-lg-6 contact-us-split-form">
              {isSubmitted ? (
                <div
                  className="thank-you-message-main-form"
                  style={{ borderRadius: "5%" }}
                >
                  <h3
                    className="thank-you-message-main-form-one ZoomIn"
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                      paddingTop: "50px",
                      borderRadius: "10px",
                    }}
                  >
                    Thank you for submitting your enquiry!
                  </h3>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn get-a-quote-btn my-4"
                      onClick={handleReset}
                    >
                      Close
                    </button>
                  </div>
                </div>
              ) : (
                <form ref={form} onSubmit={handleSubmit} method="post">
                  <div className="mb-3">
                    <label htmlFor="name" className="contact-us-form-label">
                      Name*
                    </label>
                    <input
                      type="text"
                      className="contact-us-form-control contact-us-transparent-input"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter first and last name"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="contact-us-form-label">
                      Email*
                    </label>

                    <input
                      type="email"
                      className="contact-us-form-control contact-us-transparent-input"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Eg. youremail@email.com"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="phone_number"
                      className="contact-us-form-label"
                    >
                      Phone Number*
                    </label>

                    <input
                      type="text"
                      className="contact-us-form-control contact-us-transparent-input"
                      id="phone_number"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleChange}
                      placeholder="Enter 10-digit mobile number"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="contact-us-form-label">
                      Message*
                    </label>

                    <textarea
                      className="contact-us-form-control contact-us-transparent-input"
                      id="message"
                      value={formData.message}
                      onChange={handleChange}
                      name="message"
                      required
                      placeholder="Your Message"
                    ></textarea>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type="file"
                      name="file"
                      class="form-control"
                      id="file"
                      onChange={handleFileChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Captcha />
                  </div>
                  <button
                    type="submit"
                    className="contact-us-btn btn-primary contact-us-btn-custom"
                  >
                    Submit
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareersForm;
