import React, { useState } from "react";
import styles from "../../css/Header.module.scss";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div>
        <div className={`${styles["image-header"]} mb-5`}>
          <Navbar
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            toggleMenu={toggleMenu}
          />
          <div
            className=" d-none  d-xl-block container-fluid container-fluid-sm container-fluid-md container-fluid-lg container-fluid-xl container-fluid-xxl h-89"
            style={{ width: "93%" }}
          >
            <div
              className={`row position-relative ${styles["header-container"]} px-3 h-85 my-4`}
            >
              <div
                className={`${styles["left-pane"]} col-lg-6 d-flex justify-content-center align-items-start flex-column`}
                style={{ zIndex: 2 }}
              >
                <div className={`${styles["text-fields"]}  `}>
                  <h1 className={styles["font-bold"]}>
                    Complete Logistics <br /> Solutions
                  </h1>
                  <p className={styles["font-paragraph"]}>
                    {" "}
                    Reliable. Efficient. Seamless.
                  </p>
                  <div className="col-lg-9 p-0">
                    <p className={styles["font-paragraph-one"]}>
                      ALSI: Your dedicated partner for comprehensive logistics
                      services and solutions. With expertise in customs
                      clearance, transportation, freight forwarding, project
                      cargo services and warehousing, we optimize your supply
                      chain. Partner with ALSI and experience hassle-free
                      logistics that help achieve your business goals.
                    </p>
                  </div>
                  <div className="d-flex justify-content-center justify-content-lg-start align-items-center">
                    <Link
                      to="/about_us"
                      className={` read-more-btn d-flex justify-content-center  align-items-center`}
                    >
                      Read More
                      <div className="icon-arrow ms-2">
                        <i className="bi bi-arrow-right"></i>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid  d-xl-none">
          <div
            className={`row position-relative ${styles["header-container"]} px-4 h-75 my-3`}
          >
            <div
              className={`${styles["left-pane"]} col-lg-12 d-flex justify-content-center align-items-start flex-column text-center`}
              style={{ zIndex: 2 }}
            >
              <div className={`${styles["text-fields"]}  `}>
                <h1 className={`${styles["font-bold"]} mb-4`}>
                  Complete Logistics <br /> Solutions
                </h1>
                <p className={styles["font-paragraph"]}>
                  {" "}
                  Reliable. Efficient. Seamless.
                </p>
                <p className={styles["font-paragraph-one"]}>
                  ALSI: Your dedicated partner for comprehensive logistics
                  services and solutions. With expertise in customs clearance,
                  transportation, freight forwarding, project cargo services and
                  warehousing, we optimize your supply chain. Partner with ALSI
                  and experience hassle-free logistics that help achieve your
                  business goals.
                </p>
                <div className="d-flex justify-content-center  align-items-center">
                  <a href="/about_us" className={`${styles["read-more-link"]}`}>
                    Read More
                    <img
                      loading="lazy" 
                      alt="Complete Logistics Solutions"
                      style={{ width: "40px", paddingLeft: "5px" }}
                      src="/images/select-arrow.webp"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
