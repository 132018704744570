import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const SlidePrevButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className=" outline-none me-4 service-button"
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
      onClick={() => swiper.slidePrev()}
    >
      <i class="bi bi-arrow-left"></i>
    </button>
  );
};
const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none  service-button"
      onClick={() => swiper.slideNext()}
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
    >
      <i class="bi bi-arrow-right"></i>
    </button>
  );
};

const Services = () => {
  const [autoplay, setAutoplay] = useState(true);
  let cards = [
    {
      title: "Road Freight",
      image: "./images/Services/road.webp",
      description:
        "Road freight solutions offering reliable, on-time deliveries, leveraging our extensive network and expertise to optimize your ground transportation needs.",
      link: "/services/road_freight",
    },
    {
      title: "Ocean Freight",
      image: "./images/Services/sea.webp",
      description:
        " Ocean freight solutions which ensure cost-effective, timely cargo transport, utilizing our global network and industry expertise for efficient ocean shipping.",
      link: "/services/ocean_freight",
    },
    {
      title: "Air Freight",
      image: "./images/Services/air.webp",
      description:
        "Air freight services which provide rapid and dependable cargo transport, leveraging our extensive global network and industry know-how for efficient air shipping solutions.",
      link: "/services/air_freight",
    },
    {
      title: "Project Cargo Services",
      image: "./images/Services/cargo.webp",
      description:
        "Specialized in project cargo services, handling complex and oversized shipments with precision, ensuring successful project execution.",
      link: "/services/project_cargo_services",
    },
    {
      title: "Warehouse Distribution",
      image: "./images/Services/warehouse.webp",
      description:
        "Our streamlined solutions ensure efficient storage and delivery, optimizing supply chains with timely and reliable services.",
      link: "/services/warehouse_distribution",
    },
    {
      "title": "Vessel Agency",
      "image": "/images/Services/vessel-icon.webp",
      "description": "Our offerings include full ship agency services for seamless cargo operations, owners protective agency to safeguard your interests, and expert husbandry services.",
      "link": "/services/vessel_agency"
    },
    {
      title: "Specialized Services",
      image: "./images/Services/special.webp",
      description:
        " Offering specialized services tailored to meet your unique logistics needs, ensuring exceptional solutions for your business.",
      link: "/services/specialized_services",
    },
    {
      title: "Customs Clearance",
      image: "./images/Services/custom.webp",
      description:
        " Streamlined customs clearance for swift and compliant international trade, reducing delays and ensuring smooth global transactions.",
      link: "/services/customs_clearance",
    },
  ];
  return (
    <>
      <div className="container container-sm container-md container-lg container-xl container-xxl my-5">
        <h1 className="car-service-cards-heading text-center">Our Services</h1>

        <div className="car-service-cards my-5">
          <div className="service-swiper-wrapper">
            <Swiper
              spaceBetween={0}
              slidesPerView={"auto"}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              loop={true}
              navigation={false}
              modules={[Autoplay]}
              breakpoints={{
                768: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
              }}
              onSlideChange={() => setAutoplay(true)}
              className="services-slider"
            >
              {cards.map(({ title, image, description, link }, index) => (
                <SwiperSlide key={`${title}_index`}>
              <Link to={link} style={{ textDecoration: "none"}}>
                  <div class="card card-med">
                    <div class="d-flex justify-content-center align-items-center w-100 h-50">
                    <img
                          src={image}
                          className="card-img-top"
                          width="100"
                          alt={title} 
                          loading="lazy"
                          style={{ width: "65px", height: "auto" }}
                        />
                    </div>
                    <div class="d-flex justify-content-start align-items-center flex-column">
                      <h2>
                          {title}
                      </h2>
                      <p>{description}</p>
                    </div>
                  </div>
                  </Link>
                </SwiperSlide>
              ))}

              <div className="arrows-service">
                <div className="col-lg-12 ">
                  <div className="col-lg d-flex justify-content-center">
                    <SlidePrevButton />
                    <SlideNextButton />
                  </div>
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
