import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import "../../css/ContactCss/ContactUsForm.scss";
import Captcha from "../Captcha/index";

const ContactUsForm = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phonenumber: "",
    message: "",
    email: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: name === "phonenumber" ? value.replace(/\D/g, "") : value,
    });
  };

  const handleReset = () => {
    setIsSubmitted(false);
    setFormData({
      name: "",
      email: "",
      phonenumber: "",
      message: "",
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dkjlsjm",
        "template_7fy8cfn",
        form.current,
        "uSYoMcGgpZy6M-o8W"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail(event);
    setIsSubmitted(true);
  };


  return (
    <div className="split-form-container ">
      <div className="container container-sm container-md container-lg container-xl container-xxl ">
        <div className="row">
          <div className="col-lg-6 career-left d-flex justify-content-center align-items-center">
            <h1 className="career-left-text ">Let's Connect And Talk </h1>
          </div>
          <div className="col-lg-6 contact-us-split-form">
            {isSubmitted ? (
              <div
                className="thank-you-message-main-form"
                style={{ borderRadius: "5%" }}
              >
                <h3
                  className="thank-you-message-main-form-one ZoomIn"
                  style={{
                    color: "#fff",
                    fontWeight: "800",
                    borderRadius: "10px",
                  }}
                >
                  Thank you for submitting your enquiry!
                </h3>

                <div className="text-center">
                  <button
                    type="button"
                    className="btn get-a-quote-btn my-4"
                    onClick={handleReset}
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : (
              <form ref={form} onSubmit={handleSubmit} method="post">
                <div className="mb-3">
                  <label htmlFor="name" className="contact-us-form-label">
                    Name*
                  </label>
                  <input
                    type="text"
                    className="contact-us-form-control contact-us-transparent-input"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter first and last name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="contact-us-form-label">
                    Email*
                  </label>
                  <input
                    type="email"
                    className="contact-us-form-control contact-us-transparent-input"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Eg. youremail@email.com"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="phonenumber"
                    className="contact-us-form-label"
                  >
                    Phone Number*
                  </label>
                  <input
                    type="text"
                    className="contact-us-form-control contact-us-transparent-input"
                    id="phonenumber"
                    name="phonenumber"
                    value={formData.phonenumber}
                    onChange={handleChange}
                    placeholder="Enter 10-digit mobile number"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="contact-us-form-label">
                    Message*
                  </label>
                  <textarea
                    className="contact-us-form-control contact-us-transparent-input"
                    id="message"
                    value={formData.message}
                    onChange={handleChange}
                    name="message"
                    required
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <div className="mb-3">
                  <Captcha />
                </div>
                <button
                  type="submit"
                  className="contact-us-btn btn-primary contact-us-btn-custom"
                >
                  Submit
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
