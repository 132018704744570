import React, { useState } from "react";
import "../../css/global.css";
import "./../../css/ModalCss/GetQuote.css";
import GetAQuoteModal from "./GetAQuote";
const GetAQuote = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  return (
    <div>
      <button type="button" className="get-a-qoute" onClick={handleModalOpen}>
        Get A Quote
      </button>
      <GetAQuoteModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default GetAQuote;
