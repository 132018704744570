import React from "react";
import Style from "../imagecards.module.scss";
import { Link } from "react-router-dom";

const RTLCard = ({
  image,
  description,
  title,
  leftPane,
  rightPane,
  readMore,
  altText,
}) => {
  return (
    <div
      className={`row image-cards-container w-100 mx-auto ${Style["section-container"]}`}
    >
      <div
        className={`col-lg-6 ${Style["content-container"]} image-cards-left `}
        {...leftPane}
      >
        <div className={`${Style["content"]}`}>
          <h1 className="mb-3">{title}</h1>
          <div>
            {description.map((desc, index) => (
              <p className="" key={index}>
                {desc}
              </p>
            ))}
          </div>
          {readMore && readMore !== "" && (
            <div>
              <Link
                to={readMore}
                className={`d-flex  align-items-center ${Style["read-more-rtl"]}`}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Learn more
                <div
                  className={` d-flex justify-content-center align-items-center ms-3 ${Style["icon-arrow"]}`}
                >
                  <i className="bi bi-arrow-right "></i>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div
        className="col-lg-6 image-cards-right"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
        }}
        {...rightPane}
      >
        {altText && <img src={image} alt={altText} />}
      </div>
    </div>
  );
};

export default RTLCard;
