import React, { useState, useRef, useEffect } from "react";
import Style from "./blog.module.scss";
import { Link } from "react-router-dom";
import GetAQuote from "../../components/GetAQuote";

const Blog = ({ introduction, blogs, recentPosts }) => {
  const [isOpen, setIsOpen] = useState(false);
  const recentPostsRef = useRef(null);
  const blogContainerRef = useRef(null);

  useEffect(() => {
    const recentPostsObserver = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (!entry.isIntersecting) {
          blogContainerRef.current.classList.add("scrolling");
        } else {
          blogContainerRef.current.classList.remove("scrolling");
        }
      },
      {
        threshold: 0.8,
      }
    );

    recentPostsObserver.observe(recentPostsRef.current);

    return () => {
      recentPostsObserver.disconnect();
    };
  }, []);

  return (
    <>
      <div className="container container-sm container-md container-lg container-xl container-xxl ">
        <div className="col-lg-12 my-4">
          {introduction !== "" && (
            <div className={`${Style["introduction"]}`}>{introduction}</div>
          )}
        </div>
        <div className="row">
          <div className="col-lg-6" ref={blogContainerRef}>
            {blogs.map((blog, index) => (
              <div key={index} className="step" data-index={index}>
                {blog.image && blog.image !== "" && (
                  <img
                    src={blog.image}
                    className="customs-center-bg"
                    alt={`Customs Clearance - ${index + 1}`}
                    loading="lazy"
                  />
                )}
                <h6 className="my-2 ">{blog.title}</h6>
                {blog?.description?.length > 0 && (
                  <>
                    {blog?.description.map((description, descIndex) => (
                      <p
                        key={descIndex}
                        style={{ fontSize: "14px" }}
                        dangerouslySetInnerHTML={{
                          __html: description.replace(
                            /(<a[^>]+)>/g,
                            '$1 style="text-decoration: none;">'
                          ),
                        }}
                      />
                    ))}
                  </>
                )}

                <ol>
                  {blog?.steps?.length > 0 &&
                    blog.steps.map((step, stepIndex) => (
                      <li key={stepIndex}>
                        <h6>{step.title}</h6>
                        <dd>{step.description}</dd>
                      </li>
                    ))}
                </ol>
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            <div
              className="sticky"
              style={{
                backgroundColor: "rgb(239, 239, 239)",
                padding: "30px",
                position: "sticky",
                top: 145,
              }}
            >
              <h5 style={{ fontWeight: "800" }}>Recent Posts</h5>
              <div ref={recentPostsRef}>
                {recentPosts.map(
                  ({ image, title, description, blogIndex }, recentPostIndex) => (
                    <div className="mt-3" key={recentPostIndex}>
                      <img
                        src={image}
                        className="w-100"
                        style={{ width: "100%", height: "22vh" }}
                        alt={`Recent Post - ${recentPostIndex + 1}`}
                        loading="lazy"
                      />
                      <h6 className="my-3" style={{ fontWeight: "800" }}>
                        {title}
                      </h6>
                      <p style={{ fontSize: "14px" }}>{description}</p>
                      <Link
                        to={`/market-updates/${blogIndex}`}
                        className="recent-read"
                        style={{ textDecoration: "none", padding: 0 }}
                      >
                        Read More
                      </Link>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetAQuote showModal={isOpen} setShowModal={setIsOpen} />
    </>
  );
};

export default Blog;
