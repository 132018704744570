import React, { useState, useEffect } from "react";
import "../../css/AboutCss/AboutMilestones.css";
import slideBluePartner from "../../images/AboutCelebs/timeline-blue-partner.webp";
import slideWhitePartner from "../../images/AboutCelebs/timeline-white-partner.webp";
import pin from "../../images/AboutCelebs/pin.webp";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { relative } from "path-browserify";

const SlidePrevButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className=" outline-none   me-4 service-button"
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
      onClick={() => swiper.slidePrev()}
    >
      <i class="bi bi-arrow-left"></i>
    </button>
  );
};
const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none  service-button"
      onClick={() => swiper.slideNext()}
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
    >
      <i class="bi bi-arrow-right"></i>
    </button>
  );
};

const AboutMilestones = (props) => {
  let milestoneArray = [
    {
      title: "2008",
      subtitle: "Foundation of Excellence",
      acheivements: [
        "Established the Sohar Head Office",
        "Started with a team of 6 dedicated professionals",
      ],
    },
    {
      title: "2010",
      subtitle: "Expanding Reach",
      acheivements: [
        "Inaugurated the Muscat Branch Office",
        "Growing team, now with 9 members",
      ],
    },
    {
      title: "2011",
      subtitle: "Border Ventures",
      acheivements: [
        "Introduced Wajajah Export, Wajajah Import, and Buraimi Branch Offices",
        "Team expanded to 20 individuals",
      ],
    },
    {
      title: "2013",
      subtitle: "Strides Towards Growth",
      acheivements: [" Advanced to a 25-member strong team"],
    },
    {
      title: "2017",
      subtitle: "Zones of Innovation",
      acheivements: [
        "Launched Muscat Airport Branch Office",
        "Team now consists of 70 skilled members",
      ],
    },
    {
      title: "2020",
      subtitle: "Dual Dynamics",
      acheivements: [
        "Expanded into Muscat Sales and Dubai Branch Offices",
        "Team growth to 78 dedicated experts",
      ],
    },
    {
      title: "2021",
      subtitle: "Expanding Horizons",
      acheivements: [
        "Established Duqm and Hatta Branch Offices",
        "Team reached 85 committed individuals",
        "Awarded Best Customs Clearance Company of 2021 by Oman Customs",
      ],
    },
    {
      title: "2022",
      subtitle: "Defying Challenges",
      acheivements: [
        "Introduced Rub Al Khali and Qatar Branch Offices",
        "Team extended to 104 members ",
        "Awarded Best Customs Clearance Company of 2022 Award by Oman Customs",
        "Honored with Smart Workplace 2022 Award by Dubai Customs",
      ],
    },
    {
      title: "2023",
      subtitle: "Regional Resilience",
      acheivements: [
        "Established Saudi Branch Office",
        "Team expanded to 135 skilled professionals",
      ],
    },
    {
      title: "",
      subtitle: "",
      acheivements: [
        // "A journey of growth, achievements and unwavering dedication",
        // "Delivering exceptional customs clearance, transportation and logistics services",
      ],
    },
  ];
  let milestones = [...milestoneArray, ...milestoneArray];

  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (swiper && swiper.slideNext) {
        swiper.slideNext();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [swiper]);

  return (
    <>
      {/* for larger screens */}
      <div className="container container-sm container-md container-lg container-xl container-xxl">
        <div className="row milestone-heading">
          <div className="col-lg-12">
            <h1 className="milestones-head">Celebrating 15 Years!</h1>
            <p className="milestones-parag">Journey Through Milestones</p>
          </div>
        </div>
      </div>

      <div className="about-slider-container d-flex align-items-center d-none d-lg-flex">
        <div
          className="about-slider d-grid justify-content-center align-items-center"
          style={{ gridTemplateColumns: "repeat(10,257px 110px)" }}
        >
          {milestones.map((slide, index) => (
            <div
              className="position-relative d-flex justify-content-center align-items-center"
              style={{
                width: "100%",
                height: "294px",
              }}
            >
              <div className="position-relative w-100 h-100">
                <img
                  src={index % 2 === 0 ? slideWhitePartner : slideBluePartner}
                  alt={`Timeline ${index + 1}`}
                  style={{
                    width: index % 2 === 0 ? "100%" : "111%",
                    height: index % 2 === 0 ? "130px" : "34px",
                    position: "absolute",
                    top: index % 2 === 0 ? "50% " : "calc(50% - 48px)",
                    left: index % 2 === 0 ? 0 : "-5px",
                    transform: "translateY(-50%)",
                  }}
                />
                <img
                  src={index % 2 === 0 ? slideWhitePartner : slideBluePartner}
                  alt={`Timeline ${index + 1}`}
                  style={{
                    width: index % 2 === 0 ? "100%" : "111%",
                    height: index % 2 === 0 ? "131px" : "34px",
                    position: "absolute",
                    top: index % 2 === 0 ? "50% " : "calc(50% - 48px)",
                    left: index % 2 === 0 ? 0 : "-5px",
                    transform: "translateY(-50%)",
                  }}
                />
                {slide.subtitle !== "" && (
                  <img
                    src={pin}
                    style={{
                      height: "196px",
                      position: "absolute",
                      top: index % 2 === 0 ? "99%" : "2px",
                      left: "50%",
                      transform:
                        index % 2 !== 0
                          ? "translate(-50%, -50%)"
                          : "translate(-50% ,-50%) rotate(180deg)",
                    }}
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    top: index % 2 !== 0 ? "-115px" : "unset",
                    bottom: index % 2 !== 0 ? "unset" : "-100px",
                    left: index % 2 !== 0 ? "45%" : "64%",
                    transform:
                      index % 2 === 0
                        ? `translate( -50%, 100%)`
                        : "translate(-30%, -87%)",
                  }}
                >
                  <ul
                    className="p-0"
                    style={{
                      width: "300px",
                    }}
                  >
                    {slide.acheivements.map((acheivement, acheivementIndex) => (
                      <li key={acheivementIndex}>
                        <p className="m-0" style={{ fontSize: "14px" }}>
                          {acheivement}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* for alsi logo */}
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: "196px",
                  position: "absolute",
                  height: "185px",
                  backgroundImage:
                    slide.subtitle !== "" &&
                    `url(${
                      index % 2 !== 0
                        ? "images/Vector-2.webp"
                        : "images/Vector-1.webp"
                    })`,
                  backgroundSize: "196px",
                  backgroundRepeat: "no-repeat",
                  top:
                    index % 2 === 0 ? "calc(50% - 50px)" : "calc(50% + 63px)",
                  left: index % 2 === 0 ? "50%" : "calc(50% + 1px)",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div
                  className={`d-flex justify-content-center align-items-center flex-column  ${
                    index % 2 !== 0 ? "text-white" : ""
                  }`}
                  style={{ whiteSpace: "break-spaces" }}
                >
                  <h3>{slide.title}</h3>
                  <p style={{ fontSize: "13px" }}>{slide.subtitle}</p>
                </div>
              </div>

              {slide.subtitle === "" && (
                <img
                  src="images/nav_logo.webp"
                  alt="ALSI Global"
                  style={{
                    width: "125px",
                    height: "165px",
                    position: "absolute",
                    top: "-10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundImage: `url(${index % 2 !== 0 ? "" : ""})`,
                  }}
                />
              )}
            </div>
          ))}
        </div>

      </div>

      {/* for smaller screens */}
      <div className="container">
        <div
          className="row d-flex d-lg-none pb-5 pt-2"
          style={{ overflowX: "auto" }}
        >
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            navigation={false}
            className="h-100"
            breakpoints={{
              768: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
            }}
            onSwiper={(swiper) => setSwiper(swiper)}
            onTouchStart={() => {
              if (swiper && swiper.autoplay && swiper.autoplay.running) {
                swiper.autoplay.stop();
              }
            }}
            onTouchEnd={() => {
              if (swiper && swiper.autoplay && !swiper.autoplay.running) {
                swiper.autoplay.start();
              }
            }}
          >
            {milestoneArray.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="d-flex justify-content-center align-items-center flex-column"
                  style={{ width: "100%" }}
                >

                   {/* for alsi logo */}
                  <div
                    className="background-image-container"
                    style={{
                      backgroundImage:
                        item.subtitle !== "" &&
                        `url(${
                          index % 2 !== 0
                            ? "images/Vector-2.webp"
                            : "images/Vector-1.webp"
                        })`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      height: "300px",
                      width: "300px",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div
                      className={`text-container d-flex justify-content-center align-items-center flex-column  ${
                        index % 2 !== 0 ? "text-white" : ""
                      }`}
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      <h3 style={{ paddingTop: "41%", fontSize: "30px" }}>
                        {item.title}
                      </h3>
                      <p style={{ fontSize: "15px", fontWeight: "600" }}>
                        {item.subtitle}
                      </p>
                    </div>
                    {item.subtitle === "" && (
                      <img
                        src="images/nav_logo.webp"
                        alt=""
                        style={{
                          width: "150px",
                          height: "200px",
                          position: "absolute",
                          top: "60%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",

                          backgroundImage: `url(${index % 2 !== 0 ? "" : ""})`,
                        }}
                      />
                    )}
                  </div>

                  <div className="d-flex ">
                    <ul
                      className="p-0"
                      style={{
                        margin: "2% 6% 2% 6%",
                      }}
                    >
                      {item.acheivements.map(
                        (acheivement, acheivementIndex) => (
                          <li key={acheivementIndex}>
                            <p className="m-0" style={{ fontSize: "14px" }}>
                              {acheivement}
                            </p>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="w-100 d-flex justify-content-center align-items-center pt-2">
              <SlidePrevButton />
              <SlideNextButton />
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default AboutMilestones;
