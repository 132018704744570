import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./css/global.css";
import "./css/inputs.scss";
import "react-tooltip/dist/react-tooltip.css";
import Header from "./components/Home/Header";
import Services from "./components/Home/Services";
import Network from "./components/Home/Network";
import ChooseUs from "./components/Home/ChooseUs";
import Differentiators from "./components/Home/Differentiators";
import Achievements from "./components/Home/Achievements";
import Highlights from "./components/Home/Highlights";
import Blog from "./components/Home/Blog";
import Industries from "./components/Home/Industries";
import Footer from "./components/Home/Footer";
import Form from "./components/Home/Form";
import Modal from "./components/Modal/Modal";
import ServicesNavbar from "./components/Services/ServicesNavbar";
import AboutHeader from "./components/About/AboutHeader";
import AboutMilestones from "./components/About/AboutMilestones";
import AboutTeam from "./components/About/AboutTeam";
import AboutSimply from "./components/About/AboutSimply";
import AboutCertified from "./components/About/AboutCertified";
import CustomsHeader from "./components/CustomsDedicatedBlogs/CustomsHeader";
import CustomsCentered from "./components/CustomsDedicatedBlogs/CustomsCentered";
import ContactUs from "./components/Contact/ContactUs";
import ContactUsForm from "./components/Contact/ContactUsForm";
import OurIndustriesHeader from "./components/Industries/OurIndustriesHeader";
import IndustriesOil from "./components/Industries/IndustriesOil";
import BlogSpecial from "./components/Market/BlogSpecial";
import BlogSpecialHeader from "./components/Market/BlogSpecialHeader";
import GetAQuote from "./components/GetAQuote";
import Service from "./Page/Service";
import OurNetwork from "./Page/OurNetwork";
import MarketUpdates from "./Page/MarketUpdates";
import MarketUpdatePost from "./Page/MarketUpdates/MarketUpdate";
import Careers from "./Page/Careers";
import NotFound from "./Page/NotFound";
import AboutStorySection from "./components/About/AboutStorySection/AboutStorySection";
import { Helmet } from "react-helmet";
import Gallery from "./Page/Gallery/index.jsx";
import GalleryIftarMuscat from "./Page/Gallery/DedicatedGalleryPage/GalleryIftarMuscat/index.jsx";
import GalleryIftarSohar from "./Page/Gallery/DedicatedGalleryPage/GalleryIftarSohar/index.jsx";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="overflow-hidden">
              <Helmet>
                <title>
                  Logistics Services and Shipping Companies in Muscat, Oman
                </title>
                <meta
                  name="description"
                  content="Explore reliable and exceptional logistics services with ALSI, a leading company among shipping and logistics companies in Oman and Muscat."
                />
                <meta
                  name="keywords"
                  content="shipping companies in oman, logistics companies in oman, logistics companies in muscat, shipping companies in muscat, logistics services, logistics in oman"
                />
                <meta property="og:url" content="https://www.alsiglobal.com" />
                <meta
                  property="og:title"
                  content="Logistics Services and Shipping Companies in Muscat, Oman"
                />
                <meta
                  property="og:description"
                  content="Explore reliable and exceptional logistics services with ALSI, a leading company among shipping and logistics companies in Oman and Muscat."
                />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.alsiglobal.com" />
              </Helmet>
              <Header />
              <Services />
              <Network />
              <ChooseUs />
              <Differentiators />
              <Achievements />
              <Highlights />
              <Industries />
              <Blog />
              <Form />
              <Footer />
              <GetAQuote />
            </div>
          }
        />

        <Route
          path="/about_us"
          element={
            <div className="overflow-hidden">
              <Helmet>
                <title>
                  Freight Forwarding Companies and Forwarders in Oman
                </title>
                <meta
                  name="description"
                  content="ALSI, your reliable partner in freight forwarding companies and trusted freight forwarders in Oman, ensuring efficient and seamless cargo transportation."
                />
                <meta
                  name="keywords"
                  content="freight forwarding companies in oman, freight forwarders in oman"
                />
                <meta
                  property="og:url"
                  content="https://www.alsiglobal.com/freight_forwarders_and_forwarding_companies_in_oman"
                />
                <meta
                  property="og:title"
                  content="Freight Forwarding Companies and Forwarders in Oman"
                />
                <meta
                  property="og:description"
                  content="ALSI, your reliable partner in freight forwarding companies and trusted freight forwarders in Oman, ensuring efficient and seamless cargo transportation."
                />
                <meta property="og:type" content="website" />
                <link
                  rel="canonical"
                  href="https://www.alsiglobal.com/freight_forwarders_and_forwarding_companies_in_oman"
                />
              </Helmet>
              <ServicesNavbar />
              <AboutHeader />
              <AboutStorySection />
              <AboutMilestones />
              <AboutTeam />
              <AboutSimply />
              <AboutCertified />
              <Form />
              <Footer />
            </div>
          }
        />

        <Route
          path="/services/:type/:dedicated?"
          element={
            <div className="overflow-hidden">
              <Service />
            </div>
          }
        />

        <Route
          path="/our_network/"
          element={
            <div className="overflow-hidden">
              <Helmet>
                <title>Our Logistics and Shipping Network | ALSI Global</title>
                <meta
                  name="description"
                  content="Explore the efficiency of our logistics and shipping network for customized solutions to optimize your operations."
                />
                <meta
                  property="og:url"
                  content="https://www.alsiglobal.com/our_logistics_and_shipping_network"
                />
                <meta
                  property="og:title"
                  content="Our Logistics and Shipping Network | ALSI Global"
                />
                <meta
                  property="og:description"
                  content="Explore the efficiency of our logistics and shipping network for customized solutions to optimize your operations."
                />
                <meta property="og:type" content="website" />
                <link
                  rel="canonical"
                  href="https://www.alsiglobal.com/our_logistics_and_shipping_network"
                />
              </Helmet>
              <OurNetwork />
            </div>
          }
        />

        <Route
          path="/market-updates/"
          element={
            <div className="overflow-hidden">
              <Helmet>
                <title>Market Updates | ALSI Global</title>
                <meta
                  name="description"
                  content="Stay informed with our latest market updates for valuable insights and informed decisions."
                />
                <meta
                  property="og:url"
                  content="https://www.alsiglobal.com/market-updates"
                />
                <meta
                  property="og:title"
                  content=" Market Updates | ALSI Global"
                />
                <meta
                  property="og:description"
                  content="Stay informed with our latest market updates for valuable insights and informed decisions."
                />
                <meta property="og:type" content="website" />
                <link
                  rel="canonical"
                  href="https://www.alsiglobal.com/market-updates"
                />
              </Helmet>
              <MarketUpdates />
            </div>
          }
        />

        <Route
          path="/market-updates/:type"
          element={
            <>
              <MarketUpdatePost />
            </>
          }
        />

        <Route
          path="/industries"
          element={
            <div className="overflow-hidden">
              <Helmet>
                <title>Industries We Cater | ALSI Global</title>
                <meta
                  name="description"
                  content="Discover the industries we cater to for tailored solutions that meet your specific needs."
                />
                <meta
                  property="og:url"
                  content="https://www.alsiglobal.com/industries"
                />
                <meta
                  property="og:title"
                  content="Industries We Cater | ALSI Global"
                />
                <meta
                  property="og:description"
                  content="Discover the industries we cater to for tailored solutions that meet your specific needs."
                />
                <meta property="og:type" content="website" />
                <link
                  rel="canonical"
                  href="https://www.alsiglobal.com/industries"
                />
              </Helmet>
              <ServicesNavbar />
              <OurIndustriesHeader />
              <IndustriesOil />
              <Services />
              <Form />
              <Footer />
            </div>
          }
        />

        <Route
          path=""
          element={
            <>
              <ServicesNavbar />
              <BlogSpecialHeader />
              <BlogSpecial />
              <Form />
              <Footer />
            </>
          }
        />

        <Route
          path="/smooth_sailing_through_customs_clearance_in_oman_a_guide_to_ensuring_compliance_avoiding_penalties_and_navigating_potential_pitfalls"
          element={
            <>
              <ServicesNavbar />
              <CustomsHeader />
              <CustomsCentered />
              <Form />
              <Footer />
            </>
          }
        />

        <Route
          path="/gallery"
          element={
            <div>
              <Helmet>
                <title>Gallery | ALSI Global</title>
                <meta name="gallery" content="Our Gallery" />
              </Helmet>
              <Gallery />
            </div>
          }
        />

        <Route
          path="/gallery/iftar_sohar"
          element={
            <>
              <Helmet>
                <title>Iftar Sohar | Gallery | ALSI Global</title>
                <meta name="irfar sohar" content="Iftar Sohar" />
              </Helmet>
              <GalleryIftarSohar />
            </>
          }
        />
        <Route
          path="/gallery/iftar_muscat"
          element={
            <>
              <Helmet>
                <title>Iftar Muscat | Gallery | ALSI Global</title>
                <meta name="irfar muscat" content="Iftar Muscat" />
              </Helmet>
              <GalleryIftarMuscat />
            </>
          }
        />

        <Route
          path="/contact_us"
          element={
            <div className="overflow-hidden">
              <Helmet>
                <title>Contact Us Today | ALSI Global</title>
                <meta
                  name="description"
                  content="Get in touch with us today for all your inquiries and assistance need."
                />
                <meta
                  property="og:url"
                  content="https://www.alsiglobal.com/contact_us"
                />
                <meta
                  property="og:title"
                  content="Contact Us Today | ALSI Global"
                />
                <meta
                  property="og:description"
                  content="Get in touch with us today for all your inquiries and assistance needs."
                />
                <meta property="og:type" content="website" />
                <link
                  rel="canonical"
                  href="https://www.alsiglobal.com/contact_us"
                />
              </Helmet>
              <ServicesNavbar />
              <ContactUs />
              <ContactUsForm />
              <Footer />
            </div>
          }
        />

        <Route
          path="/modal"
          element={
            <>
              <Modal />
            </>
          }
        />

        <Route
          path="/careers"
          element={
            <div className="overflow-hidden">
              <Helmet>
                <title>Careers | ALSI Global</title>
                <meta
                  name="description"
                  content="Take the next step in your career with us. Explore opportunities and grow professionally."
                />
                <meta
                  property="og:url"
                  content="https://www.alsiglobal.com/careers"
                />
                <meta
                  property="og:title"
                  content="Contact Us Today | ALSI Global"
                />
                <meta
                  property="og:description"
                  content="Take the next step in your career with us. Explore opportunities and grow professionally."
                />
                <meta property="og:type" content="website" />
                <link
                  rel="canonical"
                  href="https://www.alsiglobal.com/careers"
                />
              </Helmet>
              <Careers />
            </div>
          }
        />

        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
