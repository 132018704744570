import React from "react";
import "../../css/IndustriesCss/IndustriesOil.scss";
import ImageCard from "../ImageCards";

const IndustriesOil = () => {
  let props = [
    {
      flow: "ltr",
      image: "images/Industries/oil-gas.webp",
      title: "Oil and Gas",
      description: [
        "In the oil and gas industry, we recognize its pivotal nature. At Alsi, we prioritize safety, precision, and efficiency, offering specialized logistics solutions meticulously tailored to fulfill its unique requirements. Whether it's the transportation of critical equipment or the intricate management of project cargo, ALSI is steadfastly positioned as your trusted partner, fully committed to ensuring seamless operations within the oil and gas sector. Our unwavering dedication stems from an understanding of the industry's critical role in providing essential energy resources. With ALSI as your logistics partner, you can rely on our expertise and reliability to support and enhance your endeavors. We are here to facilitate the uninterrupted flow of resources, ensuring the success of your mission within this vital sector. For oil and gas logistics, ALSI is the name you can trust for unwavering dedication and excellence.",
      ],
    },
    {
      flow: "rtl",
      image: "images/Industries/beverages.webp",
      title: "Food and Beverages",
      leftPane: {
        style: {
          background: "transparent",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          color: "black",
        },
      },

      description: [
        "In the fast-paced landscape of the Food and Beverage industry, ALSI stands as a steadfast provider of reliable logistics solutions. Our primary goal is to preserve the freshness and timeliness of your products. Through temperature-controlled warehousing, efficient transportation, and specialized expertise in handling delicate cargo, we have honed our capabilities to become the ideal partner for F&B businesses. In today's competitive market, your success hinges on the quality and consistency of your supply chain. ALSI understands the critical nature of this relationship and is dedicated to delivering both. When you choose ALSI you are choosing a partner committed to upholding the highest standards in logistics, ensuring your products reach their destinations fresh and on time, every time. Trust ALSI for the unwavering quality and reliability your Food and Beverage business demands.",
      ],
    },
    {
      flow: "ltr",
      image: "images/Industries/chemical.webp",
      title: "Chemical",
      leftPane: {
        style: {
          backgroundSize: "cover",
        },
      },
      description: [
        "In the intricate world of the chemicals industry, ALSI emerges as your steadfast logistics partner, placing utmost importance on safety and precision. We specialize in the secure transportation and storage of hazardous materials, rigorously adhering to stringent regulations Our expertise, coupled with state-of-the-art infrastructure, guarantees the absolute integrity of your chemical cargo. From its initiation point to the ultimate destination, ALSI meticulously oversees every aspect of the supply chain. Within the complex landscape of chemical logistics, ALSI is the guardian you can rely on. Our core values revolve around safety, precision, and compliance, ensuring the protection of your investments, your reputation, and, most crucially the well-being of all stakeholders. ALSI is your unwavering partner, dedicated to safeguarding and optimizing your chemical supply chain, where safety and precision are non-negotiable.",
      ],
    },

    //Retail
    {
      flow: "rtl",
      image: "images/Industries/Retail.webp",
      title: "Retail",
      leftPane: {
        style: {
          background: "transparent",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          color: "black",
        },
      },
      description: [
        "In the ever-evolving retail landscape, ALSI offers bespoke logistics solutions meticulously crafted to align with your precise requirements. Be it optimizing inventory management, ensuring timely deliveries, or streamlining warehousing and distribution, we stand as your dedicated partner for achieving success. Recognizing the paramount significance of speed and precision in the retail sector, ALSI is deeply committed to elevating the efficiency of your supply chain. We understand that these factors are instrumental in meeting the demands of a dynamic market, and we are steadfast in our commitment to enhancing your operational excellence. In the dynamic world of retail, count on ALSI to be your trusted ally, delivering tailored logistics solutions designed to help you thrive and stay ahead of the competition. Your success is our ultimate goal.",
      ],
    },

    //Energy
    {
      flow: "ltr",
      image: "images/Industries/energy-img.webp",
      title: "Energy",
      description: [
        "Energy logistics is the lifeblood of our modern world, ensuring the seamless flow of essential energy resources. At ALSI, we specialize in energy logistics, providing comprehensive solutions tailored to the unique needs of the industry. From resource extraction and production to efficient distribution and timely delivery, ALSI serves as your trusted partner at every step. We understand the critical significance of the energy sector and the imperative for secure, reliable, and cost-effective logistics. With cutting-edge infrastructure, a seasoned team of experts, and an unwavering commitment to safety and compliance, ALSI stands as a leader in energy logistics. Whether it's managing the supply chain for oil, gas, renewables, or electricity, we are dedicated to optimizing your operations and minimizing downtime.",
      ],
    },

    //Automotive
    {
      flow: "rtl",
      image: "images/Industries/motive.webp",
      title: "Automotive",
      leftPane: {
        style: {
          background: "transparent",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          color: "black",
        },
      },
      description: [
        "In the automotive sector, ALSI takes the wheel in driving excellence through our comprehensive logistics solutions. Our expertise spans from the manufacturing floor to dealerships, where we specialize in the efficient transportation and management of vehicles and automotive components. This dedication ensures on-time deliveries and cost-effective supply chain management. Count on ALSI to optimize your automotive  logistics, bolstering your competitiveness and elevating customer satisfaction. In an industry where precision and timeliness are paramount, we are your trusted partner for success. Our commitment to seamless operations and efficient logistics processes means that your automotive business can run smoothly and effectively, meeting the high demands of the market. Choose ALSI to be your driving force in the automotive sector, and let us steer your supply chain toward excellence, delivering results that satisfy both your business goals and your customers.",
      ],
    },

    //Pharma and Healthcare
    {
      flow: "ltr",
      image: "images/Industries/pharma.webp",
      title: "Pharma and Healthcare",
      leftPane: {
        style: {
          backgroundSize: "cover",
        },
      },
      description: [
        " Within the complex and critical landscape of Pharma and Healthcare logistics, ALSI takes the helm, steering excellence through our comprehensive logistics solutions. Our expertise extends from pharmaceutical manufacturing facilities to healthcare institutions, where we specialize in the precise transportation and management of vital medical supplies and healthcare equipment. This commitment ensures punctual deliveries and cost-effective supply chain management. Count on ALSI to optimize your Pharma and Healthcare logistics, enhancing your competitiveness and elevating patient care. In an industry where precision and timeliness are paramount, we are your trusted partner for success. Our unwavering dedication to seamless operations and efficient logistics processes means your healthcare business can run smoothly, meeting the high demands of the healthcare sector.",
      ],
    },

    //Aviation and Aerospace
    {
      flow: "rtl",
      image: "images/Industries/aeros.webp",
      title: "Aviation and Aerospace ",
      leftPane: {
        style: {
          background: "transparent",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          color: "black",
        },
      },
      description: [
        "In the aerospace industry, precision timing is of the utmost importance. ALSI specializes in managing time-sensitive shipments, including critical Aircraft on Ground (AOG) support, ensuring the rapid delivery of essential components to minimize costly downtime. As a seasoned partner for Original Equipment Manufacturers (OEMs), we possess an intricate understanding of the intricate world of aerospace logistics. Our unwavering commitment to precision and stringent compliance guarantees that your aerospace projects remain firmly on track. Place your trust in ALSI for excellence in aerospace logistics. We are dedicated to providing not only timely solutions but also precision and reliability at every stage of the process. In the dynamic and high-stakes realm of aerospace, ALSI is your dependable partner, committed to upholding the precision and efficiency your projects demand.",
      ],
    },

    //Manufacturing
    {
      flow: "ltr",
      image: "images/Industries/mfg.webp",
      title: "Manufacturing",
      leftPane: {
        style: {
          backgroundSize: "cover",
        },
      },
      description: [
        "In the world of manufacturing logistics, ALSI takes the lead in driving excellence through our comprehensive logistical solutions. Our expertise encompasses the entire manufacturing process, from the factory floor to distribution centers, where we specialize in the efficient transportation and management of goods and components. This unwavering commitment ensures on-time deliveries and cost-effective supply chain management. Rely on ALSI to optimize your manufacturing logistics, bolstering your competitiveness and enhancing operational efficiency. In an industry where precision and timeliness are vital, we are your trusted partner for success. Our dedication to seamless operations and efficient logistical processes means your manufacturing business can operate seamlessly and effectively, meeting the high demands of the market.",
      ],
    },
  ];
  return (
    <div className="mb-4 industries">
      <ImageCard cards={props} />
    </div>
  );
};

export default IndustriesOil;
