import React, { useState, useEffect } from "react";
import "../../css/Highlights.css";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

const SlidePrevButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none me-4 service-button"
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
      onClick={() => swiper.slidePrev()}
    >
      <i className="bi bi-arrow-left"></i>
    </button>
  );
};
const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none service-button"
      onClick={() => swiper.slideNext()}
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
    >
      <i className="bi bi-arrow-right"></i>
    </button>
  );
};

const Highlights = () => {
  const highlightsData = [
    {
      icon: "/images/Highlights/highlights-mark.webp",
      title:
        "130+ Employees <br /> <br /><br />",
      description: "",
    },

    {
      icon: "/images/Highlights/highlights-mark.webp",
      title:
        "Average of 9,000+ Customs Declarations Processed Per Month <br /><br />",
      description: "",
    },

    {
      icon: "/images/Highlights/highlights-mark.webp",
      title: "10 Offices Across Oman<br /><br /><br />",
      description: "",
    },

    {
      icon: "/images/Highlights/highlights-mark.webp",
      title: "Branch Offices in UAE, Qatar and Saudi Arabia <br /><br />",
      description: "",
    },

    {
      icon: "/images/Highlights/highlights-mark.webp",
      title: "24/7 Operations<br /><br /><br />",
      description: "",
    },

    {
      icon: "/images/Highlights/highlights-mark.webp",
      title: "ISO 9001:2015 Certified Company <br /><br /><br /> ",
      description: "",
    },

    {
      icon: "/images/Highlights/highlights-mark.webp",
      title: "Registered Member of WCA and FIATA Freight Network<br/><br/>",
      description: "",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleCardClick = (index) => {
    setCurrentIndex(index);
  };

  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (swiper && swiper.slideNext) {
        swiper.slideNext();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [swiper]);

  return (
    <>
      <div
        className="highlights-container-bg d-none d-lg-block"
        id="justify-swipper"
      >
        <div className="row justify-content-end">
          <div className="col read-icon-a">
            <h1 className="high-txt-stl">Highlights</h1>
          </div>
          {highlightsData.map((highlight, index) => (
            <div
              className={`swiper-slide-higlight ${
                index === currentIndex ? "active" : ""
              }`}
              key={index}
            >
              <div className="highlight-item">
                <img
                  src={highlight.icon}
                  className="multi-pad pb-4"
                  style={{ width: "55px" }}
                  alt={`Highlight ${index + 1}`}
                  loading="lazy" 
                />
                <h1
                  className="highlights-text pb-2"
                  dangerouslySetInnerHTML={{ __html: highlight.title }}
                />
                <p
                  className="highlight-text-one"
                  dangerouslySetInnerHTML={{ __html: highlight.description }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container-fluid highlights-bg-second d-lg-none">
        {/* Responsive highlight items */}
        <div className="row highlights-second-head">
          <div className="col-lg-12 highlights-second-main">
            <h1>Highlights</h1>
          </div>
        </div>

        <div className="container">
          <div className="row highlights-f-mode d-flex d-lg-none">
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              centeredSlides={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: true,
              }}
              loop={true}
              navigation={false}
              className="h-100"
              breakpoints={{
                768: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
              }}
              onSwiper={(swiper) => setSwiper(swiper)}
              onTouchStart={() => {
                if (swiper && swiper.autoplay && swiper.autoplay.running) {
                  swiper.autoplay.stop();
                }
              }}
              onTouchEnd={() => {
                if (swiper && swiper.autoplay && !swiper.autoplay.running) {
                  swiper.autoplay.start();
                }
              }}
            >
              {highlightsData.map((highlight, index) => (
                <SwiperSlide key={index}>
                  <div className="highlights-f-mode marquee">
                    <div
                      className={`col-lg card-wrap-second${
                        index === currentIndex ? " active" : ""
                      }`}
                      key={index}
                      onClick={() => handleCardClick(index)}
                    >
                      <div
                        className={`highlight-item-second ${
                          index === currentIndex ? " active" : ""
                        }`}
                      >
                        <img
                          src={highlight.icon}
                          className="multi-pad-second pb-4"
                          style={{ width: "55px" }}
                          alt={`Highlight ${index + 1}`}
                          loading="lazy" 
                        />
                        <h1
                          className="highlights-text pb-2"
                          dangerouslySetInnerHTML={{ __html: highlight.title }}
                        />
                        <p
                          className="highlight-text-one"
                          dangerouslySetInnerHTML={{ __html: highlight.description }}
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              <div className="w-100 d-flex justify-content-center align-items-center">
                <SlidePrevButton />
                <SlideNextButton />
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Highlights;