import React from "react";
import "../../css/AboutCss/AboutHeader.scss";
import "./../../css/ModalCss/Modal.module.scss";
import GetAQuote from "../GetAQuote";

const AboutHeader = () => {
  return (
    <>

      <div className="container-fluid about-us-nav-bg about-us-quote-img">
        <div className="col-lg-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12 about-us-quote">
              <h1 className="about-us-text text-center">About Us</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="col-lg-12 col-sm col-md "></div>
      </div>
      <GetAQuote />
    </>
  );
};

export default AboutHeader;
