import React, { useEffect } from "react";
import { Card, Col } from "react-bootstrap";
import Style from "./cardtile.module.scss";

const CardTile = ({ cards, cardsPerRow, cardCount }) => {
  let tiles = cards;

  return tiles.map((section, rowIndex) => (
    <div key={rowIndex} className={`${Style["section"]} position-relative`}>
      <div className="container container-sm container-md container-lg container-xl container-xxl">
        <h1 {...section.heading}>{section?.heading?.text}</h1>
      </div>
      <div
        key={rowIndex}
        className={`${Style["section"]} position-relative`}
        style={{
          height:
            window.screen.width > 1200
              ? section.items.length === 1 && "700px"
              : "auto",
        }}
      >
        <div className="container container -sm container-md container-lg container-xl container-xxl h-100">
          {section.items.map((rows, sectionIndex) => (
            <div key={sectionIndex} className={` ${Style["single-row"]} row`}>
              <h1 {...rows.heading}>{rows?.heading?.text}</h1>
              {rows?.items?.map(({ image, title, ...rest }, index) => (
                <Col
                  md={6}
                  lg={cardsPerRow}
                  key={index}
                  {...rest}
                  className="mb-4 mb-lg-0  d-flex justify-content-center align-items-center "
                >
                  <Card
                    className={`${Style["single-card"]} w-100`}
                    style={{ maxWidth: "400px" }}
                  >
                    {image && (
                      <Card.Img
                        src={image}
                        alt={`${title} - ${index + 1}`}
                        className="cc-type-serv-general"
                      />
                    )}
                    <Card.Body>
                      <Card.Title>{title}</Card.Title>
                      <Card.Text></Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  ));
};

export default CardTile;
